import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Mainbanner from './components/Mainbanner'
const App = () => {
  return (
    <Mainbanner />
  );
}

export default App;
