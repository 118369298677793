import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import king from '../images/king.jpg'
import Techgrid from './Techgrid'
import Myfolio from './Myfolio'
import Mycontact from './Mycontact'
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
const Mainbanner = () => {
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShow1(true);
        }, 2500);
        setTimeout(() => {
            setShow2(true);
        }, 3000)
        setTimeout(() => {
            setShow3(true);
        }, 4000);
        setTimeout(() => {
            setShow4(true);
        }, 5000)
    }, [])

    return (
        <div className="App">
            <header className="App-header">
                <Container>
                    <Row>
                        <Col md={6} lg={6} sm={12} xs={12}>
                            <Slide left >
                                <Card style={{ width: '99%' }}>
                                    <Card.Img variant="top" src={king} />
                                    <Card.Body>
                                        <Fade when={show2}>
                                            <Card.Text className="ctxt">
                                                <Mycontact />
                                            </Card.Text></Fade>
                                    </Card.Body>
                                </Card>
                            </Slide>
                        </Col>
                        <Col md={6} lg={6} sm={12} xs={12}>
                            <Slide right >
                                <Card style={{ width: '99%' }}>

                                    <Card.Body>
                                        <Fade when={show1}>       <Card.Title className="chead">Kingshuk Chakraborty</Card.Title></Fade>
                                        <Fade when={show2}>    <Card.Text className="ctxt text-center text-bold">
                                            A full stack developer who builds simple solutions to any given business model or real-world problems.
                                        </Card.Text>
                                            <Card.Text className="ctxt">
                                                I am passionate about creating solution with multiple programming languages. I am straight forward and believer in direct talk. Building efficient solutions for a problem is my high priority. I have multiple years of remote work experience and I am comfortable working during EST and PST hour.
                                            </Card.Text></Fade>
                                        <Fade when={show3}>
                                            <Card.Text className="ctxt">
                                                <Techgrid />
                                            </Card.Text>
                                        </Fade>

                                        <Slide up when={show4} >  <Button variant="primary">KNOW MORE</Button>  </Slide>
                                    </Card.Body>
                                </Card>
                            </Slide>
                        </Col>
                    </Row>
                </Container>
            </header>
        </div>
    )
}

export default Mainbanner