import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import angular from '../images/angular.png'
import aws from '../images/aws.png'
import hooks from '../images/hooks.png'
import js from '../images/js.png'
import lara from '../images/lara.png'
import mongo from '../images/mongo.png'
import mysql from '../images/mysql.png'
import native from '../images/native.png'
import node from '../images/node.jpg'
import php from '../images/php.png'
import python from '../images/python.png'
import rreact from '../images/react.png'
import rest from '../images/rest.png'
import serverless from '../images/serverless.png'

const Techgrid = () => {
    return (
        <Container>
            <Row>
                <Col md={3} sm={4} xs={4}>
                    <img src={angular} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={aws} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={hooks} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={js} className="tlogo" />
                </Col>


                <               Col md={3} sm={4} xs={4}>
                    <img src={lara} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={mongo} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={mysql} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={native} className="tlogo" />
                </Col>


                <Col md={3} sm={4} xs={4}>
                    <img src={node} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={php} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={python} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={rreact} className="tlogo" />
                </Col>



                <Col md={3} sm={4} xs={4}>
                    <img src={rest} className="tlogo" />
                </Col>
                <Col md={3} sm={4} xs={4}>
                    <img src={serverless} className="tlogo" />
                </Col>

            </Row>
        </Container>
    )
}

export default Techgrid