import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
const Mycontact = () => {
    return (
        <Container>
            <Row>
                <Col md={6} lg={6} sm={12} xs={12}><strong>CALL</strong></Col>
                <Col md={6} lg={6} sm={12} xs={12}>+91 9051530883</Col>
            </Row>
            <Row>
                <Col md={6} lg={6} sm={12} xs={12}><strong>WHATSAPP</strong></Col>
                <Col md={6} lg={6} sm={12} xs={12}>+91 9674697623</Col>
            </Row>
            <Row>
                <Col md={6} lg={6} sm={12} xs={12}><strong>MAIL</strong></Col>
                <Col md={6} lg={6} sm={12} xs={12}>kingpop000@gmail.com</Col>
            </Row>
        </Container>
    )
}

export default Mycontact